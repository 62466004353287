



































import { defineComponent, reactive, ref } from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";
import LoadingButton from "@/shared/components/LoadingButton";
import { RasmusForgotPasswordApiData } from ".";

import { ApiErrorType } from "@/features/rasmus-kids-club/shared";

import {
  rasmusRequest,
  getTranslatedErrorForMessage,
} from "@/features/rasmus-kids-club/shared/helpers";

export default defineComponent({
  name: "RasmusForgotPasswordForm",
  components: {
    LoadingButton,
    ValidationObserver,
  },
  setup() {
    let formData = reactive({
      email: "",
    });

    let errors: ApiErrorType = reactive({
      messages: undefined,
    });

    let successMessage: { messages: undefined | string } = reactive({
      messages: undefined,
    });

    let isSubmitting = ref(false);
    let forgotPassword = ref(false);

    const setForgotPassword = (bool: boolean) => {
      forgotPassword.value = bool;
    };

    const generateRequestData = (): RasmusForgotPasswordApiData => ({
      action: "SEND_FORGOT_MAIL",
      data: {
        email: formData.email,
      },
    });

    const resetMessages = () => {
      errors.messages = undefined;
      successMessage.messages = undefined;
    };

    const handleSubmit = async () => {
      resetMessages();
      isSubmitting.value = true;
      const apiRequestData = generateRequestData();
      const response = await rasmusRequest<RasmusForgotPasswordApiData>(
        apiRequestData
      );
      isSubmitting.value = false;

      if (response.success) {
        successMessage.messages =
          "Wir haben Ihnen eine E-Mail mit der Anleitung zum Zurücksetzen Ihres Passwortes geschickt.";
      } else {
        const translatedMessage = getTranslatedErrorForMessage(
          response.message
        );

        errors.messages = translatedMessage;
      }
    };

    return {
      formData,
      handleSubmit,
      isSubmitting,
      forgotPassword,
      setForgotPassword,
      successMessage,
      errors,
    };
  },
});
