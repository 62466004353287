






















































import { defineComponent, reactive, ref } from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";
import LoadingButton from "@/shared/components/LoadingButton";
import { RasmusForgotPasswordForm } from "@/features/rasmus-kids-club/users/forgot_password";
import { RasmusUserLoginApiData } from ".";

import {
  RasmusUserForm,
  ApiErrorType,
} from "@/features/rasmus-kids-club/shared";

import {
  rasmusRequest,
  redirectToPath,
  getTranslatedErrorForMessage,
} from "@/features/rasmus-kids-club/shared/helpers";

export default defineComponent({
  name: "RasmusUserLogin",
  components: {
    RasmusUserForm,
    LoadingButton,
    ValidationObserver,
    RasmusForgotPasswordForm,
  },
  setup() {
    let formData = reactive({
      email: "",
      password: "",
    });

    let errors: ApiErrorType = reactive({
      messages: undefined,
    });

    let isSubmitting = ref(false);
    let forgotPassword = ref(false);

    const setForgotPassword = (bool: boolean) => {
      forgotPassword.value = bool;
    };

    const generateRequestData = (): RasmusUserLoginApiData => ({
      action: "LOGIN",
      data: {
        email: formData.email,
        password: formData.password,
      },
    });

    const handleSubmit = async () => {
      isSubmitting.value = true;
      const apiRequestData = generateRequestData();
      const response = await rasmusRequest<RasmusUserLoginApiData>(
        apiRequestData
      );
      isSubmitting.value = false;

      if (response.success) {
        // Implements fallback for Typo3 sending blank redirectUrl String when failing to generate
        const url = response.data?.redirectUrl
          ? response.data?.redirectUrl
          : "/rasmus-club/dashboard";

        redirectToPath(url);
      } else {
        const translatedMessage = getTranslatedErrorForMessage(
          response.message
        );

        errors.messages = translatedMessage;
      }
    };

    return {
      formData,
      handleSubmit,
      isSubmitting,
      forgotPassword,
      setForgotPassword,
      errors,
    };
  },
});
