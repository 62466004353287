










import { defineComponent } from "@vue/composition-api";
import RasmusForgotPasswordForm from "./RasmusForgotPasswordForm.vue";

export default defineComponent({
  name: "RasmusForgotPassword",
  components: {
    RasmusForgotPasswordForm,
  },
  setup() {
    return {};
  },
});
